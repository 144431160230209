import {
    EventCardActions,
    EventCardBadge,
    EventCardBOGOLabel,
    EventCardBookmarksCount,
    EventCardDate,
    EventCardDetails,
    EventCardDetailsSpacer,
    EventCardImage,
    EventCardLink,
    EventCardLocation,
    EventCardPrice,
    EventCardPromoCode,
    EventCardPromoted,
    EventCardSaveAction,
    EventCardShareAction,
    EventCardTitle,
    EventCardTracking,
    EventCardUrgencySignals,
    getEventCardLinkProps,
    getFormattedSalesStatus,
    getShareActionProps,
    HorizontalEventCard,
    HorizontalEventCardColumn,
    HORIZONTAL_EVENT_CARD_IMAGE_CONFIG,
    HORIZONTAL_LEFT_CARD_BADGE_STYLE,
    useFormattedLocationString,
    useGetFormattedEventDateTimeString,
    useMinPriceString,
} from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import { Button } from '@eventbrite/marmalade';
import React from 'react';

import { useEnvContext } from '../../../context';
import { useShowLikesExperiment } from '../../../pages/search/hooks';
import { defaultUtmOptions } from '../defaults';
import { getEventCardSignalsVisibility } from '../Experiments/eventCardSignalsVisibility';
import { DiscoverEventCardProps } from '../types';
import styles from './DiscoverHorizontalEventCard.module.scss';

export const DiscoverHorizontalEventCard: React.FunctionComponent<
    DiscoverEventCardProps
> = ({
    event,
    locale,
    isRaised,
    isLazyImage,
    statsigLocationString,
    affCode = 'ebdssbdestsearch',
    moreActions,
    isExpansionsLoading,
    statsigMetadata,
    onShare,
    shareOptions = {},
    onClick,
    isAuthenticated,
    hasFullClick,
    hasReducedSignals = false,
    hasCTA,
}) => {
    const { user } = useEnvContext();
    const withUpsellModal = !!user?.orgHasEvents;

    const { utmOptions = defaultUtmOptions } = shareOptions;
    const { urgencySignals } = event;

    const { showUrgencySignals, showDiscountCode, showBOGOLabel } =
        getEventCardSignalsVisibility({
            event,
            withLimitedVisibility: hasReducedSignals,
        });

    const linkProps = getEventCardLinkProps({
        event,
        affCode: affCode,
        onClick,
    });

    const formattedDateString = useGetFormattedEventDateTimeString({
        startDate: event?.startDate,
        startTime: event?.startTime,
        timezone: event?.timezone,
        repeatingInstanceCount: event?.repeatingInstanceCount,
        locale: locale,
        useUserTimezone: event.isOnlineEvent,
        dateTimeDelimeter: '•',
    });

    const formattedLocationString = useFormattedLocationString({
        venue: event.venue,
        style: 'venue',
    });

    const formattedPriceString = useMinPriceString({
        price: event?.minPrice,
        locale: locale,
    });

    //Only show active Sales Status (sold out etc)
    //For non-repeating events to prevent incorrect signals.
    const formattedSalesStatus = getFormattedSalesStatus(event);

    const eventCardTrackingProps = {
        statsigLocationString: statsigLocationString,
        event,
        statsigMetadata,
    };

    const isShowLikesExperimentEnabled = useShowLikesExperiment(
        event.isPromoted,
    );

    return (
        <section className={styles.cardWrapper}>
            <HorizontalEventCard isRaised={isRaised} actionVisibility="hidden">
                <EventCardTracking {...eventCardTrackingProps}>
                    <EventCardBadge style={HORIZONTAL_LEFT_CARD_BADGE_STYLE}>
                        {formattedSalesStatus}
                    </EventCardBadge>
                    <HorizontalEventCardColumn
                        tokens={{
                            '--HorizontalCardColumnMarginRight': '24px',
                        }}
                    >
                        <EventCardLink {...linkProps}>
                            <EventCardImage
                                name={event.name}
                                loading={isLazyImage ? 'lazy' : 'eager'}
                                tokens={{
                                    ...HORIZONTAL_EVENT_CARD_IMAGE_CONFIG,
                                    '--image-background-color': event.edgeColor,
                                }}
                                src={event.imageUrl}
                            />
                        </EventCardLink>
                    </HorizontalEventCardColumn>
                    <EventCardDetails
                        space="xs"
                        tokens={{
                            '--EventCardDetailsPadding': '0 16px 0 0',
                            '--EventCardDetailsFlexGrow': '2',
                            '--EventCardDetailsPosition': 'relative',
                        }}
                    >
                        {!formattedSalesStatus && showUrgencySignals && (
                            <EventCardUrgencySignals
                                className={styles.urgencySignals}
                                urgencySignals={urgencySignals}
                            />
                        )}
                        <EventCardLink {...linkProps}>
                            <EventCardTitle
                                className="event-card__clamp-line--two"
                                title={event.name}
                                color="neutral-800"
                            />
                        </EventCardLink>
                        <EventCardDate
                            className="event-card__clamp-line--one"
                            formattedDateString={formattedDateString}
                            variant="body-md"
                        />
                        <EventCardLocation
                            className="event-card__clamp-line--one"
                            formattedLocationString={formattedLocationString}
                        />
                        <EventCardDetailsSpacer />
                        <div className={styles.priceWrapper}>
                            <EventCardPrice
                                isFree={event.isFree}
                                formattedPriceString={formattedPriceString}
                                variant="body-md-bold"
                                color="neutral-800"
                                isLoading={isExpansionsLoading}
                            />
                            {showDiscountCode && (
                                <EventCardPromoCode
                                    openDiscount={event.openDiscount}
                                    locale={locale}
                                />
                            )}
                            {showBOGOLabel && (
                                <EventCardBOGOLabel
                                    specialDiscounts={event.specialDiscounts}
                                />
                            )}
                        </div>

                        <EventCardPromoted
                            className={styles.promotedLabel}
                            isPromoted={event.isPromoted}
                            withUpsellModal={withUpsellModal}
                        />
                    </EventCardDetails>
                    <EventCardActions
                        className={
                            hasCTA ? styles.actionsWithCTA : styles.actions
                        }
                    >
                        {isShowLikesExperimentEnabled && (
                            <EventCardBookmarksCount
                                bookmarksCount={event.bookmarksCount}
                                data-testid="bookmarks-count"
                            />
                        )}
                        <EventCardShareAction
                            {...getShareActionProps({
                                name: event.name,
                                id: event.id,
                                url: event.url,
                                affCode: affCode,
                                onClick: onShare,
                                utmOptions,
                            })}
                        />
                        <EventCardSaveAction
                            isAuthenticated={!!isAuthenticated}
                            savedByYou={event.savedByYou}
                            id={event.id}
                            eventName={event.name}
                            imageUrl={event.imageUrl}
                        />
                        {moreActions}
                    </EventCardActions>
                    {hasFullClick && (
                        <EventCardLink
                            {...linkProps}
                            className={styles.fullClickLink}
                            data-testid="event-card-full-click-link"
                        />
                    )}
                    {hasCTA && (
                        <EventCardLink
                            {...linkProps}
                            className={styles.ctaLink}
                        >
                            <Button variant="secondary">
                                {gettext('Get tickets')}
                            </Button>
                        </EventCardLink>
                    )}
                </EventCardTracking>
            </HorizontalEventCard>
        </section>
    );
};
