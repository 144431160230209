import { gettext } from '@eventbrite/i18n';
import { Typography, TypographyProps } from '@eventbrite/marmalade';
import classNames from 'classnames';
import React from 'react';
import styles from './EventCardBookmarksCount.module.scss';

interface EventCardBookmarksCountProps
    extends Omit<TypographyProps, 'children'> {
    bookmarksCount?: number;
}

export const EventCardBookmarksCount: React.FunctionComponent<
    EventCardBookmarksCountProps
> = ({ className, bookmarksCount, ...typographyProps }) => {
    if (!bookmarksCount) {
        return null;
    }

    const text =
        bookmarksCount === 1
            ? gettext('Liked by %(bookmarksCount)s person', { bookmarksCount })
            : gettext('Liked by %(bookmarksCount)s people', { bookmarksCount });

    return (
        <>
            <div className={classNames(className, styles.root)}>
                <Typography
                    variant="body-sm"
                    color="neutral-600"
                    {...typographyProps}
                >
                    {text}
                </Typography>
            </div>
        </>
    );
};
