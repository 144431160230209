import { NestedActionContainer } from '@eventbrite/marmalade';
import classNames from 'classnames';
import React from 'react';
import '../EventCardShared.scss';
import './HorizontalEventCard.scss';

interface HorizontalEventCardProps {
    isRaised?: boolean;
    actionVisibility?: 'hidden' | 'visible';
    fullClick?: boolean;
}

interface HorizontalEventCardColumnTokens {
    '--HorizontalCardColumnFlexDirection'?: string;
    '--HorizontalCardColumnFlexShrink'?: string;
    '--HorizontalCardColumnJustifyContent'?: string;
    '--HorizontalCardColumnMarginRight'?: string;
}

interface HorizontalEventCardColumnProps {
    tokens?: HorizontalEventCardColumnTokens;
}

export const HorizontalEventCardColumn: React.FunctionComponent<
    HorizontalEventCardColumnProps
> = (props) => {
    return (
        <section
            className="horizontal-event-card__column"
            style={props.tokens as React.CSSProperties}
        >
            {props.children}
        </section>
    );
};

const ELEVATION =
    '0px 2px 8px rgba(30, 10, 60, 0.06), 0px 4px 12px rgba(30, 10, 60, 0.08)';

export const HorizontalEventCard: React.FunctionComponent<
    HorizontalEventCardProps
> = (props) => {
    const elevation = props.isRaised ? ELEVATION : undefined;

    const horizontalEventCardClassNames = classNames(
        'event-card event-card__horizontal',
        {
            'horizontal-event-card__action-visibility':
                props.actionVisibility === 'hidden',
            'event-card__horizontal__action-visibility--raised':
                props.actionVisibility === 'hidden' && props.isRaised,
            'event-card__full-card-click': props.fullClick,
        },
    );

    return (
        <NestedActionContainer
            tokens={{
                '--ContainerPadding': !props.fullClick ? '16px' : '0px',
                '--ContainerElevation': elevation,
            }}
            className={horizontalEventCardClassNames}
        >
            {props.children}
        </NestedActionContainer>
    );
};
